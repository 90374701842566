:root {
  --still50: #f7f8fa;
  --still100: #f0f3f5;
  --still200: #dee0e2;
  --still300: #c6c6c6;
  --still400: #8f9397;
  --still500: #5c5c5c;
  --still600: #333;
  --still700: #212121;
  --still800: #111;
  --still900: #0a0a0a;
  --good50: #f6fefc;
  --good100: #e8fdf7;
  --good200: #b5f7e6;
  --good300: #87f3d7;
  --good400: #58eec7;
  --good500: #17e3ae;
  --good600: #01a982;
  --good700: #0e8b6a;
  --good800: #095843;
  --good900: #033a2c;
  --goodX50: #f3f7f7;
  --goodX100: #dde9e9;
  --goodX200: #c7dbdb;
  --goodX300: #aac9c9;
  --goodX400: #8eb7b7;
  --goodX500: #72a5a5;
  --goodX600: #567c7c;
  --goodX700: #395353;
  --goodX800: #324242;
  --goodX900: #1c2929;
  --sore50: #fff5f9;
  --sore100: #fee6f1;
  --sore200: #fec3dd;
  --sore300: #fd91c0;
  --sore400: #fc5fa3;
  --sore500: #fa1478;
  --sore600: #dc0562;
  --sore700: #a00347;
  --sore800: #64022d;
  --sore900: #390119;
  --hot50: #fafaff;
  --hot100: #f5f5ff;
  --hot200: #e5e7ff;
  --hot300: #ccceff;
  --hot400: #999eff;
  --hot500: #656cff;
  --hot600: #575bcf;
  --hot700: #4a4a9f;
  --hot800: #3c396f;
  --hot900: #1a1a40;
  --warm50: #fffbef;
  --warm100: #fff5d5;
  --warm200: #ffeeb3;
  --warm300: #ffe280;
  --warm400: #ffd64c;
  --warm500: #ffc500;
  --warm600: #e5b100;
  --warm700: #b28a00;
  --warm800: #664f00;
  --warm900: #3c2f01;
  --freeze50: #faf7fd;
  --freeze100: #f2eafa;
  --freeze200: #dfcdf4;
  --freeze300: #c4a4ea;
  --freeze400: #a97ae1;
  --freeze500: #803bd2;
  --freeze600: #6327aa;
  --freeze700: #461c78;
  --freeze800: #301056;
  --freeze900: #1e0837;
  --cold50: #f5feff;
  --cold100: #e5fcff;
  --cold200: #b3f5ff;
  --cold300: #80eeff;
  --cold400: #4ce7ff;
  --cold500: #0fdcff;
  --cold600: #00bedb;
  --cold700: #0092a8;
  --cold800: #005d6b;
  --cold900: #012f36;
  --white: #fff;
  --black: #000;
  --shadow: rgb(17 17 17 / 4%);
  --hover: rgb(0 32 64 / 5%);
  --black2pct: rgb(0 0 0 / 2%);
  --black4pct: rgb(0 0 0 / 4%);
  --black5pct: rgb(0 0 0 / 5%);
  --black8pct: rgb(0 0 0 / 8%);
  --active: rgb(0 32 64 / 2.5%);
}
