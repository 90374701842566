.content {
  padding-bottom: 0;
}

.lowerContainer {
  background-color: var(--white);
  padding: 29px 22px 32px 22px;
  gap: 49px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.ssidListItem {
  svg {
    color: var(--sore500);
  }
}

.setupLowerContainer {
  background-color: var(--white);
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.credentialInputs {
  padding: 0 20px;
}

.ssidInputIcon {
  svg {
    path {
      fill: var(--black) !important;
    }
  }
}

.setupCtaBtn {
  margin: 192px auto 0;
}

.guestSsidToggle {
  padding: 16px 0;
}

@media (max-width: 664px) {
  .ssidConfigurationInfo {
    padding: 12px;
  }

  .ssidSetup {
    padding: 12px;
  }
}
