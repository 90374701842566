.header p {
  font-weight: 500;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still50);
}

.interactive:hover:first-of-type,
.interactive:focus:first-of-type {
  border-radius: 12px 12px 0 0;
}

.interactive:hover:last-of-type,
.interactive:focus:last-of-type {
  border-radius: 0 0 12px 12px;
}

.interactive:hover:only-of-type,
.interactive:focus:only-of-type {
  border-radius: 12px;
}

.interactive:active {
  background-color: var(--still100);
}

.danger {
  color: var(--sore500);
}

.danger path {
  fill: var(--sore500);
}

.notAvailable {
  color: var(--still400);
}
