[color-theme='bell'] {
  --still50: #f4f4f4;
  --still100: #ebebeb;
  --still200: #d2d2d2;
  --still300: #999999;
  --still400: #555555;
  --still500: #555555;
  --still600: #444444;
  --still700: #333333;
  --still800: #222222;
  --still900: #111111;
  --hot50: #e6eef5;
  --hot100: #ccddeb;
  --hot200: #99bbd7;
  --hot300: #6698c2;
  --hot400: #3376ae;
  --hot500: #00549a;
  --hot600: #00437b;
  --hot700: #00325c;
  --hot800: #00223e;
  --hot900: #00111f;
  --good50: #e9fbec;
  --good100: #d2f6d9;
  --good200: #a6eeb2;
  --good300: #79e58c;
  --good400: #4ddd65;
  --good500: #339043;
  --good600: #1aaa32;
  --good700: #137f26;
  --good800: #0d5519;
  --good900: #062a0d;
  --sore50: #fce8e9;
  --sore100: #f9d1d3;
  --sore200: #f3a4a7;
  --sore300: #ee767a;
  --sore400: #e8494e;
  --sore500: #e21b22;
  --sore600: #b5161b;
  --sore700: #881014;
  --sore800: #5a0b0e;
  --sore900: #2d0507;
  --freeze50: #eaf1fc;
  --freeze100: #d5e2f8;
  --freeze200: #aac6f2;
  --freeze300: #80a9eb;
  --freeze400: #558de5;
  --freeze500: #2b70de;
  --freeze600: #225ab2;
  --freeze700: #1a4385;
  --freeze800: #112d59;
  --freeze900: #09162c;
  --goodX50: #e6eef5;
  --goodX100: #ccddeb;
  --goodX200: #99bbd7;
  --goodX300: #6698c2;
  --goodX400: #3376ae;
  --goodX500: #00549a;
  --goodX600: #00437b;
  --goodX700: #00325c;
  --goodX800: #00223e;
  --goodX900: #00111f;
  --cold50: #eaf1fc;
  --cold100: #d5e2f8;
  --cold200: #aac6f2;
  --cold300: #80a9eb;
  --cold400: #558de5;
  --cold500: #2b70de;
  --cold600: #225ab2;
  --cold700: #1a4385;
  --cold800: #112d59;
  --cold900: #09162c;
  --warm50: #fef1e9;
  --warm100: #fee3d4;
  --warm200: #fdc7a9;
  --warm300: #fcab7e;
  --warm400: #fb8f53;
  --warm500: #fa7328;
  --warm600: #c85c20;
  --warm700: #964518;
  --warm800: #642e10;
  --warm900: #321708;
  --white: #fff;
  --black: #000;
  --shadow: rgb(17 17 17 / 4%);
  --hover: rgb(0 32 64 / 5%);
  --black2pct: rgb(0 0 0 / 2%);
  --black4pct: rgb(0 0 0 / 4%);
  --black5pct: rgb(0 0 0 / 5%);
  --black8pct: rgb(0 0 0 / 8%);
  --active: rgb(0 32 64 / 2.5%);
}
