.background {
  display: flex;
  justify-content: center;
  background-color: var(--still100);

  /* only works in latest chrome/safari/edge */
  container-type: inline-size;
}

.content {
  padding: var(--screenGap);
  padding-bottom: 80px;
  gap: var(--screenGap);
  width: 100%;
  min-width: var(--minCardWithPaddingWidth);
  max-width: var(--maxContentWidth);
  grid-template-columns: repeat(3, 1fr);
}

/* calc(2 * var(--minCardWidth) + 2 * var(--screenGap)) */
@container (min-width: 752px) {
  .content {
    flex-basis: var(--maxCardDoubleWidth);
    grid-template-columns: repeat(2, 1fr);
  }
}

/* calc(3 * var(--minCardWidth) + 2 * var(--screenGap)) */
@container (min-width: 1120px) {
  .content {
    flex-basis: var(--maxCardTripleWidth);
    grid-template-columns: repeat(3, 1fr);
  }
}
