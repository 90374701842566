.navPanelWrapper {
  flex-shrink: 0;

  background-color: var(--white);

  user-select: none;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  width: 280px;

  padding: 16px 0;

  position: relative;
  left: 0;
}

@keyframes slide {
  0% {
    left: -280px;
  }

  100% {
    left: 0;
  }
}

.enter {
  animation: slide 500ms;
}

.exitActive {
  animation: slide 500ms reverse;
}

.exit {
  left: -280px;
}

@media (max-width: 664px) {
  .navPanelWrapper {
    display: none;
  }
}

.navList {
  overflow: auto;
}

.zonesChevron {
  transition: transform var(--easeOut);
}

.zonesChevron.expanded {
  transform: rotate(90deg);
}
