.header p {
  font-weight: 500;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still50);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.interactive:active {
  background-color: var(--still100);
}

.danger {
  color: var(--sore500);
}

.danger path {
  fill: var(--sore500);
}
