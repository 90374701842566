.content {
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-rows: max-content;
  grid-template-columns: min-content auto;
  grid-template-areas:
    'leftContent mainAndRightContent';
}

.contentWithNoLeft {
  width: 100%;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: 100%;
  grid-template-areas:
    'mainAndRightContent';
}

.interactive {
  cursor: pointer;
}

.leftContent {
  grid-area: leftContent;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: stretch;
}

.verticalAlignTop {
  align-items: flex-start;
}

.verticalAlignCenter {
  align-items: center;
}

.mainAndRightContent {
  grid-area: mainAndRightContent;
  gap: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainAndRightContent .main {
  min-width: 0;
}

.mainAndRightContent .main .inline {
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main.horizontalAlignLeft {
  align-items: flex-start;
}

.main.horizontalAlignCenter {
  align-items: center;
}

.main.whiteSpaceWrap {
  white-space: wrap;
  overflow: visible;
  text-overflow: inherit;
}

.right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.noGap {
  gap: 0;
}

.divider {
  width: 100%;
  border-bottom: 1px solid var(--still200);
  flex: 0 0 100%;
}