.sidePanel {
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: var(--still100);
  border-left: 1px solid var(--still200);
}

.sidePanelBell {
  height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: var(--still100);
  border-left: 1px solid var(--still200);
}

.sidePanelHeader {
  height: 48px;

  padding: 12px 16px;

  background-color: var(--white);
  border-bottom: 1px solid var(--still200);

  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}

.leftHeader,
.rightHeader {
  flex-shrink: 0;
  min-width: 24px;
}

.centerHeader {
  flex-grow: 1;
  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 473px) {
  .sidePanelHeader {
    background-color: transparent;
    border-bottom: none;
  }

  .centerHeader {
    text-align: left;
  }
}

.sidePanelContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: var(--minCardWithPaddingWidth);

  overflow: auto;
  margin: 0 auto;

  height: 100%;
  gap: 16px;
  padding: 16px;
  overflow-x: hidden;
}

.sidePanelContentWrapper p {
  overflow-wrap: anywhere;
  overflow: hidden;
}

.sidePanelContentWrapper > *:not(h3):not(button) {
  width: 100%;
}

.sidePanelContentWrapperBell {
}
