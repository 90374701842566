.screenHeader {
  padding: 8px 8px 0;

  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.mobileHeader {
  display: none;
}

@media (max-width: 664px) {
  .header {
    display: none;
  }

  .mobileHeader {
    display: inherit;
  }
}

.l,
.r {
  overflow: hidden;
  flex-grow: 1;

  display: flex;
  align-items: center;
  gap: 8px;
}

.r {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  gap: 0px;
}

.pillGroup {
  display: flex;
  gap: 4px;

  margin-left: 8px;
}

.buttonPill {
  border-color: var(--still800);
}
