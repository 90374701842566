.pendingContent {
  position: relative;
}

.pendingContentCurtain.pendingContentLoading.pendingContent::after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 80%);
}

.pendingContentHideContent.pendingContentLoading.PendingContent::after {
  background-color: #000;
  z-index: 1000;
}

.pendingContentLoader {
  position: absolute !important;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  z-index: 1001;
}

.pendingContentError {
  padding: 100px;
  text-align: center;
}
