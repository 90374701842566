.blockStandardListItem,
.inlineStandardListItem {
  padding: 16px 20px;

  flex-direction: row;
  align-items: center;

  gap: 16px;
}

.blockStandardListItem {
  display: flex;
}

.inlineStandardListItem {
  display: inline-flex;
}

.l2 {
  flex-grow: 1;

  overflow: hidden;
}

.l2l40 {
  margin-left: 40px;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still50);
  outline: var(--still50);
}

.interactive:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.interactive:active {
  background-color: var(--still100);
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.headingLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
}
