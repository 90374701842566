.mobileNavPanel {
  background-color: var(--white);

  user-select: none;

  padding-bottom: 8px;

  width: 100%;

  border-top: 1px solid var(--still200);
}

@media (min-width: 664px) {
  .mobileNavPanel {
    display: none;
  }
}

.pagesNavBar {
  display: flex;
  justify-content: space-around;
  line-height: 1;

  padding: 0 16px;
}

.zonesNavBar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.overflowContainer {
  width: fit-content;
  max-width: 100%;
  overflow: auto;
  display: flex;
  gap: 8px;
  padding: 8px;
}

.zonePill {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}

.settingsNavBar {
  padding: 8px;
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  transition: all var(--easeOut);
}

.navButton {
  flex-shrink: 0;

  color: var(--still400);
  cursor: pointer;

  transition: color var(--easeOut);

  padding: 16px;

  width: 56px;
  height: 56px;
}

.navButton.selected {
  color: var(--still800);
}

.navButton.selected::after {
  content: '';

  position: relative;
  display: block;
  width: 24px;
  margin-top: 8px;

  border: 1px solid var(--still800);
  border-radius: 2px;

  background-color: var(--still800);
}

.navButton:hover {
  background-color: var(--still100);
}

.navButton:focus {
  color: var(--still800);
  outline: none;
}

.restrictedSettingsNavBar {
  justify-content: space-around;
}
