.navLink {
  color: var(--still400);
  cursor: pointer;
  border: 1px solid var(--white);
  transition: color, background-color var(--easeOut);
}

.navLink.selected {
  color: var(--still800);
  background-color: var(--hot100);
  border: 1px solid var(--hot200);
}

.navLink:hover,
.navLink:focus {
  background-color: var(--hot100) !important;
  border: 1px solid var(--hot200) !important;
}

.navLink:active {
  background-color: var(--hot50) !important;
  border: 1px solid var(--hot100) !important;
}

.navLink[nav-item-theme='bell'] {
  color: var(--still600);
}

.navLink.selected[nav-item-theme='bell'] {
  color: var(--still800);
}
