.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--still800);
  z-index: 2;

  opacity: 0.4;
}

.dark {
  opacity: 0.4;
}

.modalEnter .modalOverlay {
  opacity: 0;
}

.modalEnterActive .modalOverlay {
  opacity: 0.4;
  transition: all var(--easeOut);
}

.modalEnterActive .dark {
  opacity: 0.4;
  transition: opacity var(--easeOut);
}

.modalExit .modalOverlay {
  opacity: 0.4;
}

.modalExitActive .modalOverlay {
  opacity: 0;
  transition: opacity var(--easeOut);
}

.modalContentWrapper {
  position: relative;
  z-index: 3;
  opacity: 1;
}

.modalEnter .modalContentWrapper {
  opacity: 0;
}

.modalEnterActive .modalContentWrapper {
  opacity: 1;
  transition: opacity var(--easeOut);
}

.modalExit .modalContentWrapper {
  opacity: 1;
}

.modalExitActive .modalContentWrapper {
  opacity: 0;
  transition: opacity var(--easeOut);
}
