.page,
.pageWrapper {
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.pageMobileIos {
  max-height: 100dvh;
}

.loaderWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageWrapper {
  display: flex;
  overflow-y: hidden;
  position: relative;
  background-color: var(--still100);
}

.pageContent {
  flex-grow: 1;
  position: relative;
  overflow: auto;
  background-color: var(--still100);
  height: calc(100vh - 48px);
}

.pageContentNoHorizontalScroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.pageContentNoVerticalScroll {
  overflow-y: hidden;
}

.question {
  position: fixed;
  top: calc(100vh - 64px);
  right: 16px;
}

@media (max-width: 664px) {
  .question {
    top: calc(100vh - 128px);
  }

  .pageContent {
    height: calc(100vh - 113px);
  }

  .questionZonesMobileNav {
    top: calc(100vh - 176px);
  }

  .pageContentZonesMobileNav {
    height: calc(100vh - 161px);
  }
}

.topBarWithSidepanelOverlay {
  z-index: 2;
}

.sidePanelRoot {
  left: 0;
  width: 100%;
  overflow: hidden;
}

@media (min-width: 480px) {
  .sidePanelRoot {
    position: relative;
    flex-shrink: 0;
    width: 0;
    max-width: 0;
    overflow: hidden;
  }

  .sidepanelWithOverlayRoot {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100dvh);
    overflow-y: auto;
    width: 0;
    max-width: 0;
    z-index: 2;
  }

  .sidepanelWithOverlayRootBell {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100dvh);
    overflow-y: auto;
    width: 0;
    max-width: 0;
    z-index: 2;
  }

  /* Remove the keyframe animation */
  .sidePanelRootEnter {
    width: 0;
    max-width: 0;
  }

  .sidePanelRootEnterActive {
    width: 385px;
    max-width: 385px;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sidePanelRootEnterDone {
    width: 385px;
    max-width: 385px;
  }

  .sidePanelRootExit {
    width: 385px;
    max-width: 385px;
  }

  .sidePanelRootExitActive {
    width: 0;
    max-width: 0;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .sidePanelRootExitDone {
    width: 0;
    max-width: 0;
  }
}
