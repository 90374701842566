.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  outline: none;
  border: none;
  border-top: 1px solid var(--still200);

  margin-top: auto;
  padding: 16px 20px;
  gap: 16px;

  background-color: var(--still50);

  cursor: pointer;
}

.cardFooter:hover,
.cardFooter:focus {
  background-color: var(--still200);
}

.cardFooter:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.cardFooterBell:hover {
  background-color: var(--still100);
}

.cardFooter:active {
  background-color: var(--still100);
}

.chevron path {
  fill: var(--still400);
}
