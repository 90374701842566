.topBar {
  height: 56px;

  padding: 0 16px;

  background-color: var(--white);
  border-bottom: 1px solid var(--still100);

  flex-shrink: 0;

  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.topBarLeft {
  display: flex;
  align-items: center;
  gap: 16px;
}

.topBarRight {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 664px) {
  .hideForNarrowScreens {
    display: none;
  }
}

.logoLine {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  gap: 16px;
}

.logo path {
  fill: var(--still600);
}

.notifications {
  position: relative;
}

.notificationsMenuEmpty {
  color: var(--still400);
  font-size: 16px;
  text-align: center;
}

.badge {
  position: absolute;
  top: -2px;
  left: 19px;
  z-index: 2;
  color: var(--white);
  background-color: var(--sore500);

  min-width: 21px;
  padding: 2px 7px;
  border-radius: 24px;
}

.badgeNarrowed {
  font-size: 10px;
  right: 1px;
}

.userButton {
  width: 40px;
  height: 40px;
  border: 1px solid var(--still100);
  border-radius: 20px;

  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--good700);
  background-color: var(--good200);
  display: flex;
  justify-content: center;
  align-items: center;
}

.userButton:hover {
  background-color: var(--good400);
}

.userButton:focus-visible {
  background-color: var(--good200);
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.userButton:active {
  background-color: var(--good600);
}

.debugMessage {
  color: var(--sore500);
}
