.miniBanner {
  width: max-content;
  min-width: 32px;
  height: 32px;

  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 3px 4px var(--shadow);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0 12px 0 10px;
  gap: 6px;
}

.set {
  color: var(--good600);
}

.set path {
  stroke: var(--good600);
}

.unset {
  color: var(--sore500);
}

.unset path {
  stroke: var(--sore500);
}

.miniBannerWrapper {
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.item {
  position: absolute;
  top: 0;
  animation: fade-in-up ease 4s 1;
  z-index: 2;
}

@keyframes fade-in-up {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-60px);
  }

  90% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (max-width: 664px) {
  @keyframes fade-in-up {
    0% {
      transform: translateY(0);
    }

    10% {
      transform: translateY(-120px);
    }

    90% {
      transform: translateY(-120px);
    }

    100% {
      transform: translateY(0);
    }
  }
}
