.button {
  border: none;
  outline: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;

  width: 100%;
  max-width: 288px;
  height: 40px;

  border-radius: 100px;

  transition: all var(--easeOut);

  cursor: pointer;
}

.button:disabled {
  cursor: default;
}

.fullWidth {
  max-width: 100%;
}

/* black theme */
.black {
  color: var(--white);
  background-color: var(--still800);
}

.black:hover,
.black:focus {
  background-color: var(--still600);
}

.black:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.black:active {
  background-color: var(--still500);
}

.black:disabled {
  background-color: var(--still200);
}

/* white theme */
.white {
  border: 0.5px solid var(--still800);
  color: var(--still800);
  background-color: var(--white);
}

.white:hover,
.white:focus {
  background-color: var(--still100);
}

.white:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.white:active {
  background-color: var(--still50);
}

.white:disabled {
  color: var(--still300);
  border-color: var(--still300);
  background-color: var(--white);
}

/* no border theme */
.noborder {
  background-color: var(--white);
}

.noborder:hover,
.noborder:focus {
  background-color: var(--still100);
}

.noborder:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.noborder:active {
  background-color: var(--still50);
}

.noborder:disabled {
  color: var(--still300);
  background-color: var(--white);
}
