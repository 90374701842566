.autoComplete {
  box-sizing: border-box;
  border: none !important;
  margin: 0 0 0 16px !important;
  align-items: center !important;
}

.autoCompleteWrapper {
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  padding: 0 16px 0 8px;
  background: var(--still100);
  margin: 0;
  border-radius: 160px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  border: 1px solid transparent;
}

.autoCompleteWrapper:focus-within {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.autoCompleteMobileContainer {
  position: static;
}

.autoCompleteBackIcon {
  margin-top: 4px;
}

.autoCompleteSeparator {
  width: 100vw;
  height: 1px;
  background: var(--still200);
  position: absolute;
  margin-top: 50px;
  left: 0;
}

.autoCompleteMobileContainer .autoCompleteWrapper {
  display: none;
}

.autoCompleteMobileContainerExpanded {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  align-items: flex-start;
  background: var(--white);
  z-index: 999;
}

.autoCompleteMobileContainerExpanded .autoCompleteWrapper {
  display: flex;
  margin-top: 8px;
  width: calc(100% - 50px);
}

.autoCompleteWrapper .iconParent {
  height: 32px;
  margin-left: 8px;
  margin-right: 16px;
}

.autoCompleteWrapperExpanded {
  border: 1px solid var(--black);
}

.autoCompleteWrapperSmall {
  height: 40px;
}

.autoCompleteWrapperIcon {
  padding-bottom: 0 !important;
}

.autoCompleteInput {
  background: transparent !important;
  padding: 0 !important;
  font-size: 16px !important;
  height: 20px;
  min-width: 218px;
}

.bellAutoCompleteInput {
  background: transparent !important;
  padding: 0 !important;
  font-size: 16px !important;
  height: 20px;
  min-width: 218px;
}

.bellAutoCompleteInput::placeholder {
  color: var(--still400);
}

.bellAutoCompleteWrapper {
  background: none !important;
  border: 1px solid var(--still400);
}

.autoCompleteResults {
  position: absolute;
  width: calc(100% + 2px);
  min-height: 60px;
  top: 36px;
  left: -1px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 3px 4px var(--shadow);
  border: 1px solid var(--still100);
  min-height: auto;
}

.autoCompleteResultsMobile {
  width: calc(100% + 34px);
  left: -32px;
  box-shadow: none;
}

.autoCompleteResultsLoader {
  justify-content: center;
}

.autoCompleteResultsItem {
  padding: 16px 20px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s background-color;
  color: var(--still800);
}

.autoCompleteResultsItemLabel {
  overflow: hidden;
  white-space: nowrap;
}

.autoCompleteResultsItemIcon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 16px;
}

.autoCompleteResultsItemIcon svg {
  height: 21px;
}

.autoCompleteResultsItem:hover {
  background-color: var(--still100);
}

.autoCompleteResultsItem:focus-visible {
  outline-offset: -1px;
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.autoCompleteResultsGroupName {
  margin: 16px 0 8px;
  padding: 8px 12px 8px 24px;
  color: var(--still400);
  font-weight: 400;
}

.autoCompleteResultsEmpty {
  padding: 16px 20px;
  color: var(--still400);
}

.shieldAutoCompleteResultsItem {
  padding: 18px 20px;
}

.autoCompleteResultsScrollbarContainer {
  border-radius: 11px;
}

.autoCompleteResultsItemActive {
  background-color: var(--still100);
}

.autoCompleteInputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.autoCompleteInputContainer label {
  position: absolute;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  color: #757575;
}

.autoCompleteInputContainer .bellAutoCompleteLabel {
  color: var(--still400);
}

.autoCompleteInputContainer input:focus + label,
.autoCompleteInputContainer input:not(:placeholder-shown) + label,
.autoCompleteInputContainer label.inputLabelHidden {
  opacity: 0;
  top: -9999px;
}

.autoCompleteInputContainer label.inputLabelHidden {
  opacity: 0;
  top: -9999px;
}
