.tooltip {
  padding: 8px 12px;
  max-width: 280px;

  background-color: var(--white);
  border: 1px solid var(--still200);
  border-radius: 6px;

  text-wrap: wrap;

  z-index: 2;
}
