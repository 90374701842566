.cardHeader {
  padding: 16px 20px 8px;

  display: flex;
  align-items: center;

  gap: 16px;

  overflow-wrap: break-word;
  overflow: hidden;
}

.l2 {
  flex-grow: 1;

  overflow: hidden;
}

.l2row {
  display: flex;
  gap: 4px;
}

.l2label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.l2pillSmall {
  flex-shrink: 1;
  max-width: fit-content;
}

.l2smallLabel {
  margin-top: 8px;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still50);
}

.interactive:active {
  background-color: var(--still100);
}
