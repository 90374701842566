.locationWrapper {
  flex-shrink: 0;

  display: flex;
  align-items: center;

  height: 48px;
  margin: 16px 16px 0;

  border-radius: 64px;
}

.still200Border {
  border: 1px solid var(--still200);
}

.still400Border {
  border: 1px solid var(--still400);
}

.locationButton {
  width: 100%;
  border-radius: 64px;
  height: 46px;
}
