:root {
  --screenGap: 16px;
  --minCardWidth: 352px;
  --minCardWithPaddingWidth: 384px;
  --maxCardWidth: 534px;
  --maxContentWidth: 1666px;
  --maxCardWithPaddingWidth: 566px;
  --maxCardSingleWidth: calc(1 * var(--maxCardWidth) + 1 * var(--screenGap));
  --maxCardDoubleWidth: calc(2 * var(--maxCardWidth) + 1 * var(--screenGap));
  --maxCardTripleWidth: calc(3 * var(--maxCardWidth) + 2 * var(--screenGap));
  --firstBreakpoint: 752px;
  --secondBreakpoint: 1120px;
  --thirdBreakpoint: 1400px;
}
