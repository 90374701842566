.button {
  border: 1px solid var(--still200);
  box-shadow: 0 3px 4px var(--shadow);

  border-radius: 50%;
  padding: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: var(--white);
}

.button:hover {
  background-color: var(--still100);
}
