.listItem {
  padding: 8px 12px;
}

.listItem.noHorizontalPadding {
  padding: 12px 0;
}

.listItem .listItem.interactive:hover {
  cursor: pointer;
  background-color: var(--still50);
}

.listItem.interactive:hover:first-of-type,
.listItem.interactive:focus:first-of-type {
  border-radius: 12px 12px 0 0;
}

.listItem.interactive:hover:last-of-type,
.listItem.interactive:focus:last-of-type {
  border-radius: 0 0 12px 12px;
}

.listItem.interactive:hover:only-of-type,
.listItem.interactive:focus:only-of-type {
  border-radius: 12px;
}
