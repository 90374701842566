.columns {
  gap: var(--screenGap);
}

.threeColumns {
  columns: auto 3;
}

.threeColumns > * {
  break-inside: avoid-column;
  margin-bottom: var(--screenGap);
}

.twoColumns {
  columns: auto 2;
}

.twoColumns > * {
  break-inside: avoid-column;
  margin-bottom: var(--screenGap);
}

.oneColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-width: var(--minCardWidth);
  max-width: var(--maxCardWidth);
}

.columns > *:last-child {
  margin-bottom: 0;
}
