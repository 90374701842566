.centerListItem {
  width: 100%;
  padding: 16px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.centerListItemFirstRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still50);
}

.interactive:active {
  background-color: var(--still100);
}
