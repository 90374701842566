.onboardingHeader {
  height: 200px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  padding: 32px;

  text-align: center;

  background-color: var(--still50);
}
