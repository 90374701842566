.text {
  font-family: Wigrum, Arial, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

.medium {
  font-weight: 500;
}

.noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.heading1 {
  font-size: 40px;
  line-height: 44px;
}

.heading2 {
  font-size: 32px;
  line-height: 36px;
}

.heading3 {
  font-size: 24px;
  line-height: 28px;
}

.heading4 {
  font-size: 20px;
  line-height: 24px;
}

.heading5 {
  font-size: 18px;
  line-height: 22px;
}

.body1 {
  font-size: 16px;
  line-height: 20px;
}

.body2 {
  font-size: 14px;
  line-height: 18px;
}

.body3 {
  font-size: 12px;
  line-height: 16px;
}

.fineprint1 {
  font-size: 10px;
  line-height: 14px;
}

.fineprint2 {
  font-size: 8px;
  line-height: 12px;
}

.subtitle1 {
  font-size: 14px;
  line-height: 14px;
}
