.interactive {
  cursor: pointer;

  transition-property: background-color;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--hover);
  outline: var(--active);
}

.interactive:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.interactive:active {
  background-color: var(--active);
}

.icon {
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all var(--easeOut);
}

.smallCircle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.circle {
  width: 40px;
  height: 40px;
  border: 1px solid var(--still100);
  border-radius: 20px;
}

.square {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
}

.rectangle {
  height: 40px;
  border: none;
  border-radius: 8px;
}
