.page,
.pageWrapper {
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  overflow: hidden;
}

.pageMobileIos {
  max-height: 100dvh;
}

.loaderWrapper {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pageWrapper {
  display: flex;
  overflow-y: hidden;
  position: relative;
  background-color: var(--still100);
}

.pageContent {
  flex-grow: 1;
  position: relative;

  overflow: auto;

  background-color: var(--still100);
  /* 100vh - top bar height */
  height: calc(100vh - 48px);
}

.pageContentNoHorizontalScroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.pageContentNoVerticalScroll {
  overflow-y: hidden;
}

.question {
  position: fixed;
  top: calc(100vh - 64px);
  right: 16px;
}

@media (max-width: 664px) {
  .question {
    top: calc(100vh - 128px);
  }

  .pageContent {
    /* 100vh - top bar height - mobile navbar height */
    height: calc(100vh - 113px);
  }

  .questionZonesMobileNav {
    top: calc(100vh - 176px);
  }

  .pageContentZonesMobileNav {
    /* 100vh - top bar height - mobile navbar height - zones nav bar */
    height: calc(100vh - 161px);
  }
}

.topBarWithSidepanelOverlay {
  z-index: 2;
}

@keyframes slide {
  0% {
    right: -385px;
  }

  100% {
    right: 0;
  }
}

.sidePanelRoot {
  left: 0;
  width: 100%;
}

@media (min-width: 480px) {
  .sidePanelRoot {
    position: relative;
    flex-shrink: 0;
    right: 0;

    width: auto;
    left: auto;
  }

  .sidepanelWithOverlayRoot {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100vh - 48px);
    overflow-y: auto;
    width: auto;
    left: auto;

    z-index: 2;
  }

  .sidePanelRootEnter {
    animation: slide 500ms;
  }

  .sidePanelRootExitActive {
    animation: slide 500ms reverse;
  }

  .sidePanelRootExit {
    right: -385px;
  }
}

.restrictedNavPanelWrapper {
  flex-shrink: 0;

  background-color: var(--white);

  user-select: none;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  width: 280px;

  padding-bottom: 16px;

  position: relative;
  left: 0;
}

@keyframes slide {
  0% {
    left: -280px;
  }

  100% {
    left: 0;
  }
}

.enter {
  animation: slide 500ms;
}

.exitActive {
  animation: slide 500ms reverse;
}

.exit {
  left: -280px;
}

.navList {
  overflow: auto;
}

.zonesChevron {
  transition: transform var(--easeOut);
}

.zonesChevron.expanded {
  transform: rotate(90deg);
}

.rightWrapper {
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: var(--maxCardWithPaddingWidth);
  width: 100%;
  flex-grow: 1;
}

.configWrapper {
  justify-content: center;
}

@media (max-width: 664px) {
  .restrictedNavPanelWrapper {
    display: none;
  }

  .ssidConfigurationInfo {
    justify-content: normal;
  }

  .configWrapper {
    justify-content: normal;
  }
}
