.logout > div > p {
  color: var(--sore500);
}

.modalBody {
  text-align: center;
}

.l2 {
  overflow: hidden;
}

.l2 p:first-of-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.accountSettingsSection {
  display: grid;
  row-gap: 16px;
}
