.pillSmall {
  height: 20px;

  background-color: var(--white);
  border: 1px solid var(--still200);
  border-radius: 24px;

  padding: 2px 10px;

  overflow: hidden;
}

.interactive {
  cursor: pointer;
}

.good {
  border: 1px solid var(--good600);
  color: var(--good600);
}

.poor {
  border: 1px solid var(--still400);
  color: var(--still400);
}
.interactive:hover,
.interactive:focus {
  background-color: var(--still100);
}

.interactive:active {
  background-color: var(--still50);
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
