.pillLarge {
  width: max-content;
  min-width: 32px;
  height: 32px;

  background-color: unset;
  border: 1px solid var(--still200);
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  background-color: var(--still100);
}

.interactive:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.interactive:active {
  background-color: var(--still50);
}

.label {
  padding: 7px 16px;
}

.iconLabel {
  padding: 4px 12px 4px 8px;
}

.labelIcon,
.iconLabelIcon {
  padding: 4px 8px;
}

.icon {
  padding: 4px;
}
