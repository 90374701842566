.card {
  min-width: var(--minCardWidth);
  max-width: var(--maxCardWidth);
  height: fit-content;

  background-color: var(--white);

  border-radius: 12px;
  box-shadow: 0 3px 4px var(--shadow);

  padding-left: 0;
  padding-right: 0;
  padding-bottom: 8px;
}

.card.noBottomPadding {
  padding-bottom: 0px;
}

.card > *:first-child,
.card > *:first-child:hover {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.withFooter > *:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.card > *:last-child:hover {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.loadingCardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80px;

  flex-grow: 1;
}

.loadingCardContent > div {
  border-color: var(--still400);
  border-bottom-color: transparent;
}

.dummy {
  min-height: 240px;
}

/* TWO COLUMN LAYOUT */
@container (min-width: 752px) {
  .firstColumn {
    grid-column-start: 1;
  }

  .secondColumn {
    grid-column-start: 2;
  }

  .thirdColumn {
    grid-column-start: 2;
  }

  .span2,
  .span3 {
    max-width: var(--maxCardDoubleWidth);
    grid-column-end: span 2;
  }
}

/* THREE COLUMN LAYOUT */
@container (min-width: 1120px) {
  .thirdColumn {
    grid-column-start: 3;
  }

  .span3 {
    max-width: var(--maxCardTripleWidth);
    grid-column-end: span 3;
  }
}

@-moz-document url-prefix("") {
  .thirdColumn {
    grid-column-start: 3;
  }

  .span3 {
    max-width: var(--maxCardTripleWidth);
    grid-column-end: span 3;
  }
}
