.listInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;
}

.listInput input::-webkit-outer-spin-button,
.listInput input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.listInput input[type='number'] {
  appearance: none;
}

.listInput input::placeholder {
  color: var(--still400);
}

.listInput hr {
  transition: border-color var(--easeOut);
}

.listInput.active hr {
  border-color: var(--hot500);
}

.listInput p {
  color: var(--still400);
}

.listInput input[type='dropdown'] {
  cursor: pointer;
}

.aboveInputLine {
  display: flex;
  align-items: flex-end;
  min-height: 40px;
}

.line {
  margin: 4px 0;
}

.belowInputLine {
  min-height: 16px;
}

.prefixIcon,
.r {
  flex-shrink: 0;
  cursor: pointer;
}

.labeledContent {
  margin: 0 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
}

.labeledContentNoIcon {
  margin: 0;
}

.prefixIcon path {
  fill: var(--still400);
}

.r path {
  fill: var(--still400);
}

.clear path {
  stroke: var(--still400);
}

.danger p {
  color: var(--sore500);
}

.listInput.danger hr {
  border-color: var(--sore500);
}

.danger rect,
.danger path {
  fill: var(--sore500);
}

.active .prefixIcon path {
  fill: var(--still800);
}

.danger .prefixIcon path {
  fill: var(--sore500);
}

.listInputDisabled {
  opacity: 0.35;
}

.iconDisabled {
  cursor: not-allowed !important;
}

.inputDisabled {
  cursor: not-allowed !important;
  color: var(--still800);
}
