.still300 {
  color: var(--still300);
}

.still400 {
  color: var(--still400);
}

.still500 {
  color: var(--still500);
}

.still600 {
  color: var(--still600);
}

.still800 {
  color: var(--still800);
}

.still900 {
  color: var(--still900);
}

.sore500 {
  color: var(--sore500);
}

.sore600 {
  color: var(--sore600);
}

.freeze500 {
  color: var(--freeze500);
}

.hot500 {
  color: var(--hot500);
}

.warm700 {
  color: var(--warm700);
}

.black {
  color: var(--black);
}
