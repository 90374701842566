.divider {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

.l16 {
  margin-left: 16px;
}

.l60 {
  margin-left: 60px;
}
