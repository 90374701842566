.pods {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.error,
.errorText {
  color: var(--sore500);
}

.actionIcon {
  color: var(--hot800);
}
