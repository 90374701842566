.formSwitch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.formSwitch i {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: var(--still400);
  border-radius: 16px;

  vertical-align: text-bottom;
  transition: all 300ms linear;
}

.formSwitch i::after {
  content: '';
  position: absolute;
  left: 0;

  width: 16px;
  height: 16px;

  background-color: var(--still50);
  border-radius: 8px;

  box-shadow: 0 0 2px rgb(0 0 0 / 16%), 0 3px 4px rgb(0 0 0 / 10%);

  transform: translate3d(4px, 4px, 0);
  transition: all 0.2s ease-in-out;
}

.formSwitch:active i::after {
  width: 16px;
  transform: translate3d(4px, 4px, 0);
}

.formSwitch input {
  display: none;
}
.formSwitch input:checked + i {
  background-color: var(--still800);
}

.formSwitch input:disabled + i {
  opacity: 0.25;
  cursor: default;
}

.formSwitch input:checked:disabled + i {
  opacity: 0.35;
  cursor: pointer;
}

.formSwitch input:checked + i::after {
  transform: translate3d(20px, 4px, 0);
}
.formSwitch:active input:checked + i::after {
  transform: translate3d(14px, 4px, 0);
}

.formSwitch:focus-visible {
  outline: none;
}

.formSwitch:focus-visible i {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}
