.settingsSectionHeader {
  min-width: 351px;
  padding: 12px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.l1 {
  width: fit-content;
  flex-shrink: 0;
}

.wrapper {
  flex-grow: 1;
}

.showHideToggle:focus-visible {
  outline-offset: 8px;
}
