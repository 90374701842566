ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
small {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

input {
  margin: 0;
  padding: 0;
  border: 0;
}

input:focus {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
}
